'use strict';

function selectFloat() {
    $('select').on('change', function () {
        if ($(this).find('option:selected').val() === null || $(this).find('option:selected').val() === '') {
            $(this).removeClass('valid');
        } else {
            $(this).addClass('valid');
        }
    });

    if ($('select').find('option:selected').val() === null || $('select').find('option:selected').val() === '') {
        $('select').removeClass('valid');
    } else {
        $('select').addClass('valid');
    }
}

// This is the custom utility for character count implementation. If needed in site any where, just add class
// 'charCountField' to the textarea and put a span for count message with id as count_message.

function charCount() {
    $('.js-charCountField').each(function () {
        var $this = $(this);
        var maxChar = parseInt($this.attr('maxlength'));
        var textLength;
        $this.on('keyup', function () {
            // For Gift Message per line, should ONLY allow 30 single-byte characters in English/Japanese and 15 Japanese double byte characters in Katakana/Kanji or Hiragana
            if (window.Resources.CURRENT_LOCALE == 'ja_JP') {
                let text = $(this).val();
                let byteLength = 0;
                let trimmed = '';
                for (let i = 0; i < text.length; i++) {
                    // check for double-byte characters
                    const charLength = /[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\u4e00-\u9faf]/.test(text[i]) ? 2 : 1;
                    if (byteLength + charLength <= maxChar) {
                        trimmed += text[i];
                        byteLength += charLength;
                    } else {
                        break;
                    }
                }
                if (text.length !== trimmed.length) {
                    $(this).val(trimmed);
                }
                textLength = byteLength;
            } else {
                textLength = $this.val().length;
            }
            $this.closest('.form-group').find('.js-char-count-msg').html(textLength + '/' + maxChar);
        });
    });
}

function stopTooltipEventPropagation() {
    $('.js-tooltip').on('click', function (e) {
        e.stopPropagation();
    });
}

function existingEmailMessageEvent() {
    $('.js-login-form .register-message a').on('click', function (e) {
        e.preventDefault();
        $(this).parents('.modal-login-content').find('#register-tab').trigger('click');
    });

    $('.js-login-form .login-message a').on('click', function (e) {
        e.preventDefault();
        $(this).parents('.modal-login-content').find('#login-tab').trigger('click');
    });
}

module.exports = {
    selectFloat: selectFloat,
    charCount: charCount,
    stopTooltipEventPropagation: stopTooltipEventPropagation,
    existingEmailMessageEvent: existingEmailMessageEvent
};
